@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';

$ingredients-drop-shadow: $ingredients-unit * .5;
$ingredients-shadow-blur: $ingredients-unit * .5;
$ingredients-shadow-opacity: .02;

@mixin shadow(
  $drop: $ingredients-drop-shadow,
  $blur: $ingredients-shadow-blur,
  $colour: rgba($colour-primary-grey, $ingredients-shadow-opacity)
) {
  box-shadow: 0 $drop $blur 0 $colour;
}
