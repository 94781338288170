@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../ingredients/styles/';

$filter-panel-vertical-group-gap: 20px;

.article {
  background-color: $colour-white;
  margin-bottom: $filter-panel-vertical-group-gap + 15px;
}

.header {
  padding: $ingredients-gutter $ingredients-gutter ($ingredients-gutter * 0.5);
  border-bottom: 1px solid $colour-mushroom-grey;
}

.title {
  margin: 0;
  font: {
    size: 16px;
    weight: 400;
  }
}

.group {
  margin: 0 $ingredients-gutter ($ingredients-gutter * 0.5);
}

.loading {
  cursor: wait;
}
