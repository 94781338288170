@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '@johnlewispartnership/wtr-ingredients/foundations/colours';

.offerControlWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;

  @include media-breakpoint-up('md') {
    width: fit-content;
  }
}

.offerText {
  color: $colour-offer-red;
  font-weight: 400;
  margin: 0 8px;


  &.disabled {
    color: $colour-waitrose-grey;
  }
}