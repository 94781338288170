@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../../ingredients/styles';

.header {
  @include shadow($ingredients-drop-shadow);
  background-color: $colour-white;
  position: relative;
  text-align: center;

  h3 {
    line-height: 56px;
    margin: 0;
    font-size: 16px;
    font-weight: normal;
  }

  p {
    font: {
      size: 12px;
      style: italic;
    }
    margin: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-modal;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: $colour-primary-grey--alpha-70;

  &:global(.ReactModal__Overlay--after-open) {
    transition: background-color .3s ease-out;
  }

  &:global(.ReactModal__Overlay--before-close) {
    opacity: 0;
    transition: background-color .3s ease-out;
  }
}

.content {
  background-color: $colour-scallop-grey;
}

.body {
  margin: 0 -20px;
  text-align: left;

  li {
    font: {
      family: $font-family-base;
      size: 16px;
    }
  }
}

.offersWrapper {
  display: flex;
  background: $colour-white;
  margin: -20px -20px 35px;
  padding: 10px 36px;
}