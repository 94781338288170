@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../../ingredients/styles/focus';

.filterForm {
  @include focus;
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-right: 8px;

  @include media-breakpoint-down('md') {
    width: 50%;
  }
}

.ssrDesktopWrapper {
  flex-grow: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.header {
  @include media-breakpoint-up('lg') {
    display: none;
  }

  div {
    background-color: $colour-white;
    border-radius: 4px;
  }
}

.button {
  background-color: $colour-white;
  border: {
    color: $colour-oyster-grey;
    radius: 6px;
    style: solid;
    width: 1px;
  }
  cursor: pointer;
  font: {
    style: normal;
    size: 12px;
  }
  line-height: 38px;
  margin: 0;
  padding: 0 40px 0 10px;
  position: relative;

  @include media-breakpoint-up('sm') {
    font-size: 16px;
    padding: 0 45px 0 15px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 29px;
    content: '\E908';
    font-family: WaitroseGlyph;
    font-size: 1.25em;
    font-style: normal;
    line-height: 38px;
    border-left: 1px solid $colour-oyster-grey;
    text-align: center;
  }
}

.count {
  margin-left: .25em;

  @include media-breakpoint-up('lg') {
    display: none;
  }
}

.break {
  flex-basis: 100%;
  height: 0;
}

.footer {
  color: $colour-waitrose-grey;
  display: block;
  margin-top: 12px;
  margin-bottom: 12px;

  span {
    display: inline-block;
  }
}

.icon {
  margin-left: 6px;
}

.clearBtn {
  color: $colour-waitrose-grey;
  margin-right: 8px;
}

.filters {
  display: none;

  @include media-breakpoint-up('lg') {
    display: flex;
  }
}
