@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins';
@import '../../../../ingredients/styles';

$radiolist-dropdown-margin-size: 4 * $ingredients-unit;

.options {
  font-size: 18px;
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up('sm') {
    font-size: 16px;
  }
}

.option {
  padding: ($ingredients-unit * 3) 0 ($ingredients-unit * 2);
  position: relative;
  width: 100%;

  &::after {
    border-bottom: 1px solid $colour-mushroom-grey;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: $radiolist-dropdown-margin-size;
    position: absolute;
    right: $radiolist-dropdown-margin-size;
  }

  &:last-child {
    &::after {
      border-bottom: 0;
    }
  }
}
