@import '../../../../styles/settings/variables';
@import '../../../../styles/tools/mixins/media-queries';


.filterCombos {
  display: flex;
  margin-right: 16px;
  background-color: $colour-white;
  height: 40px;
}

@keyframes tooltipMove
{
  0% { opacity: 0;}
  20% { opacity: 0;}
  100% {opacity: 1;}
}

.tooltip {
  &.withAnimation{
    animation-name: tooltipMove;
    animation-duration: 0.3s;
  }

  z-index: $zindex-pod-overlay + 1;

  .label {
    margin: 0;
  }

  .label,
  .closeWrapper {
    display: inline-block;
  }

  .closeWrapper {
    padding-left: 20px;
  }
}
